import React from "react"
import Select, { components } from "react-select"

export interface IOptionType {
	label: string
	value: number
}

export interface IStringOptionType {
	label: string
	value: string
}

interface ISimpleSelectorProps<T> {
	value: T
	options: T[]
	onChange: (value: number) => void
	toOption: (value: T) => IOptionType
	className?: string
	height?: number
	title?: string
	isDisabled?: boolean
	placeholder?: string
	traceName?: string
	style?: any
	isMenuTop?: boolean
}

interface ISimpleSelectorStringProps<T> {
	value: T
	options: T[]
	onChange: (value: string) => void
	toOption: (value: T | undefined) => IStringOptionType
	className?: string
	height?: number
	title?: string
	isDisabled?: boolean
	placeholder?: string
	traceName?: string
	isMenuTop?: boolean
}

//type guard
const isSimpleSelectorProps = (x: any): x is ISimpleSelectorProps<any> => true

function SimpleSelect<T>(
	props: ISimpleSelectorProps<T> | ISimpleSelectorStringProps<T>
) {
	const styles = {
		menu: (base) => ({
			...base,
			border: "1px solid var(--color-neutral-200)",
			boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.16)",
			borderRadius: "0.75em",
			marginTop: "5px",
		}),
		placeholder: (base) => ({
			...base,
			whiteSpace: "nowrap",
		}),
		menuList: (base) => ({
			...base,

			padding: "0.75em 0",

			overflow: "overlay",

			scrollbarWidth: "thin",
			scrollbarColor: "var(--color-neutral-300) #ffffff00",

			"::-webkit-scrollbar": {
				width: "0.375em",
				height: "0px",
			},
			"::-webkit-scrollbar-track": {
				background: "#f1f1f102",
				margin: "0.5em",
			},
			"::-webkit-scrollbar-thumb": {
				background: "var(--color-neutral-300)",
				borderRadius: "5px",
			},
			"::-webkit-scrollbar-thumb:hover": {
				background: "#555",
			},
		}),
		control: (base, { isFocused, isDisabled }) => {
			function themes() {
				if (isDisabled) {
					return {
						backgroundColor: "var(--color-neutral-200)",
						color: "var(--color-texticon-primary)",
						border: "1px solid var(--color-texticon-primary)",
					}
				} else if (isFocused) {
					return {
						backgroundColor: "var(--color-primary-100)",
						color: "var(--color-primary-700)",
						border: "1px solid var(--color-accent-700)",
						outline: "4px solid var(--color-accent-100)",
					}
				} else {
					return {
						backgroundColor: "var(--color-primary-100)",
						color: "var(--color-primary-700)",
						border: "1px solid var(--color-neutral-200)",
						boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.04)",
					}
				}
			}

			return {
				...base,
				borderRadius: "0.75em",
				// height: props.height || 38,
				cursor: "pointer",
				// minHeight: props.height || 38,
				height: "auto",
				":hover": {
					borderColor:
						!isFocused && !isDisabled && "var(--color-neutral-200)",
				},
				...themes(),
			}
		},

		option: (base, { isDisabled, isFocused, isSelected }) => {
			// let background
			function background() {
				if (isDisabled) {
					return "var(--color-neutral-200)"
				} else if (isSelected) {
					return "var(--color-accent-700)"
				} else if (isFocused) {
					return "var(--color-accent-100)"
				} else {
					return "var(--color-primary-100)"
				}
			}

			function color() {
				if (isDisabled) {
					return "var(--color-texticon-primary)"
				} else if (isSelected) {
					return "var(--color-primary-100)"
				} else {
					return "var(--color-primary-700)"
				}
			}

			return {
				...base,
				backgroundColor: background(),
				color: color(),
				paddingTop: "4px",
				paddingBottom: "4px",
				wordBreak: "break-word",
			}
		},
		valueContainer: (provided) => ({
			...provided,
			// height: props.height || 38,
			padding: "0.7em 0.8em",
		}),

		input: (provided) => ({
			...provided,
			margin: "0px",
			padding: 0,
		}),

		singleValue: (base) => ({
			...base,
			lineHeight: "normal",
		}),
		indicatorsContainer: (provided) => ({
			...provided,
			// height: props.height || 38
			height: "auto",
		}),

		indicatorSeparator: (base) => ({
			...base,
			display: "none",
			backgroundColor: "transparent",
		}),
	}

	const DropdownIndicator = (props) => {
		return (
			<components.DropdownIndicator {...props}>
				{
					// eslint-disable-next-line react/prop-types
					props.selectProps.menuIsOpen ? (
						<svg
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "1.5em", height: "1.5em" }}
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M11.5 9.79452L4.38058 17L3 15.6027L11.5 7L20 15.6027L18.6194 17L11.5 9.79452Z"
								fill="#686868"
							/>
						</svg>
					) : (
						<svg
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ width: "1.5em", height: "1.5em" }}
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M18.6194 7L20 8.39726L11.5 17L3 8.39726L4.38058 7L11.5 14.2055L18.6194 7Z"
								fill="#686868"
							/>
						</svg>
					)
				}
			</components.DropdownIndicator>
		)
	}

	const options = props.options.map((x) => props.toOption(x))
	let selected: IOptionType | IStringOptionType = { label: "", value: 0 };

	if (props.value !== undefined) {
		selected = props.toOption(props.value)
	}

	return (
		<Select
			options={options}
			className={props.className ?? ""}
			components={{ DropdownIndicator }}
			styles={styles}
			menuPlacement={props.isMenuTop ? "top" : "auto"}
			onChange={(newValue) => {
				if (isSimpleSelectorProps(props)) {
					if (newValue !== null)
						props.onChange(Number(newValue.value))
					else props.onChange(Number(selected.value))
				} else {
					if (newValue !== null) {
						// @ts-ignore
						props.onChange(newValue.value.toString())
					} else {
						// @ts-ignore
						props.onChange(selected.value.toString)
					}
				}
			}}
			value={selected}
			placeholder={props.placeholder}
			isDisabled={props.isDisabled ?? false}
		/>
	)
}

export default SimpleSelect
